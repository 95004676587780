import React from 'react';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { Footer } from '../footer/footer.comp';
import { Header } from '../header/header.comp';
import { IPlugin } from '../../types';

interface ILayoutProps {
	title?: string;
	description?: string;
	image?: string;
	url?: string;
	serviceName?: string;
	children?: React.ReactNode;
	showHeader?: boolean;
	showFooter?: boolean;
	noindex?: boolean;
	nofollow?: boolean;
	apps?: IPlugin[];
	isDark?: boolean;
	isDeveloper?: boolean;
	canonicalUrl?: string;
}

export const Layout = ({
	title,
	description,
	image,
	url,
	children,
	showHeader = false,
	showFooter = false,
	noindex = false,
	nofollow = false,
	isDark = false,
	isDeveloper = false,
	apps = [],
	serviceName = '',
	canonicalUrl = '',
}: ILayoutProps) => {
	return (
		<>
			<NextSeo
				// noindex={true}
				// nofollow={true}
				title={`${title
					? title
					: 'Online Bracket Maker | Create & Manage Tournaments Easily'
					}`}
				description={`${description
					? description
					: 'Welcome to the ultimate bracket maker platform. Design, customize, and manage your tournaments with ease. Dive into a seamless experience for organizers and participants alike.'
					}`}
				openGraph={{
					title: `${title
						? title
						: 'Premier Online Bracket Maker | Create & Manage Tournaments Easily'
						}`,
					description: `${description
						? description
						: 'Welcome to the ultimate bracket maker platform. Design, customize, and manage your tournaments with ease. Dive into a seamless experience for organizers and participants alike.'
						}`,
					url: url || 'https://www.bracketsninja.com',
					images: [
						{
							url:
								image ||
								'https://website-assets.commoninja.com/distribution/1692691663291_og_brackets.png',
							width: 1200,
							height: 630,
						},
					],
				}}
			/>
			<Head>
				<link rel="icon" href="https://www.commoninja.com/favicon.ico" />
				{/* {canonicalUrl &&
					<link rel="canonical" href={canonicalUrl} />
				} */}
			</Head>
			{showHeader && <Header isDark={isDark} isDeveloper={isDeveloper} />}
			{children}
			{showFooter && <Footer isDark={isDark} />}
		</>
	);
};
