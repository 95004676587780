import { createGlobalStyle } from 'styled-components';

export const CSSVars = {
  primaryFont: `var(--primary-font)`,
  secondaryFont: `var(--secondary-font)`,
  primary100: `var(--primary-100)`,
  primary300: `var(--primary-300)`,
  primary400: `var(--primary-400)`,
  primary500: `var(--primary-500)`,
  primary700: `var(--primary-700)`,
  primary800: `var(--primary-800)`,
  primary900: `var(--primary-900)`,
  gray100: `var(--gray-100)`,
  gray200: `var(--gray-200)`,
  gray300: `var(--gray-300)`,
  gray400: `var(--gray-400)`,
  gray500: `var(--gray-500)`,
  gray600: `var(--gray-600)`,
  gray700: `var(--gray-700)`,
  gray900: `var(--gray-900)`,
  error: `var(--error)`,
  success: `var(--success)`,
  warning: `var(--warning)`,
  white: `var(--white)`,
  black: `var(--black)`,
  fontSizeXS: 'var(--font-size-xs)',
  fontSizeSM: 'var(--font-size-sm)',
  fontSizeMD: 'var(--font-size-md)',
  fontSizeLG: 'var(--font-size-lg)',
  fontSizeXL: 'var(--font-size-xl)',
  fontSize2XL: 'var(--font-size-2xl)',
  fontSize3XL: 'var(--font-size-3xl)',
  fontSize4XL: 'var(--font-size-4xl)',
  fontSize5XL: 'var(--font-size-5xl)',
  fontSize6XL: 'var(--font-size-6xl)',
  breakpointSM: '480px',
  breakpointMD: '768px',
  breakpointLG: '992px',
  breakpointXL: '1280px',
  breakpoint2XL: '1536px',
};

export const GlobalStyles = createGlobalStyle`

  :root{
    --primary-font: 'Poppins', sans-serif;
    --secondary-font: 'Open Sans', sans-serif;
    --primary-100: #B8C4F5;
    --primary-300: #92A4F0;
    --primary-400: #7287e7;
    --primary-500: #596fc9;
    --primary-700: #3C4F9D;
    --primary-800: #233069;
    --primary-900: #152567;
    
    --gray-100: #FAFAFC;
    --gray-200: #F0F0F0;
    --gray-300: #D3D3D3;
    --gray-400: #A7A6B1;
    --gray-500: #5F5F73;
    --gray-600: #2F3241;
    --gray-700: #171B2C;
    --gray-900: #141414;
    --error: #E54657;
    --success: #30BB57;
    --warning: #FFC657;
    --white: #fff;
    --black: #000;

    --font-size-xs: 1.2rem;
    --font-size-sm: 1.4rem;
    --font-size-md: 1.6rem;
    --font-size-lg: 1.8rem;
    --font-size-xl: 2rem;
    --font-size-2xl: 2.4rem;
    --font-size-3xl: 3rem;
    --font-size-4xl: 3.8rem;
    --font-size-5xl: 4.8rem;
    --font-size-6xl: 6rem;

  }

  /* Reset */

  * {
    box-sizing: border-box;
  }

  /* Reset */
  
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    background: transparent;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  :focus {
    outline: 0;
  }

  ins {
    text-decoration: none;
  }

  del {
    text-decoration: line-through;
  }

  table {
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  a:focus {
    /* outline: 0; */
  }

  strong, b {
    font-weight: 700;
  }

  html {
    font-size: 62.5%; // 1rem = 10px
  }

  body {
    font-weight: 300;
    font-family: var(--primary-font);
    font-size: ${CSSVars.fontSizeMD};
  }

  .volume-slider{
    /* for audio player examples */
    background: #fff;
    padding: 5px 40px !important;
    
    input {
      padding: 0px;
      background-color: transparent;
      border: none;
    }
  }

  input{
    outline: none !important;
    padding: 5px 20px 5px 35px;
    font-size: ${CSSVars.fontSizeSM};
    border: 1px solid ${CSSVars.gray300};
    border-radius: 5px;
    color: ${CSSVars.gray600};
    font-weight: 300;
    font-family: ${CSSVars.primaryFont};
    
    &::placeholder{
      color: ${CSSVars.gray400};
    }
  }
`;