import { IoIosArrowForward } from '@react-icons/all-files/io/IoIosArrowForward';
import Image from 'next/image';
import Link from 'next/link';
import { IPlugin } from '../../../types';
import { PopoverDiv } from './menuPopovers.styles';

interface IPopoverProps {
	isDark?: boolean;
	columns?: { title: string; apps: IPlugin[]; }[];
	show?: boolean;
}

export const TypesPopover = ({ isDark, show }: IPopoverProps) => {
	return (
		<PopoverDiv className={`${isDark ? 'dark' : ''} ${show ? 'show' : ''}`}>
			<div className={`solutions  ${isDark ? 'dark' : ''}`}>
				<Link href={'/types/single-elimination-bracket'}>Single Elimination</Link>
				<Link href={'/types/double-elimination-bracket'}>Double Elimination</Link>
				<Link href={'/types/march-madness-bracket'}>March Madness</Link>
				<Link href={'/types/round-robin-bracket'}>Round Robin</Link>
				<Link href={'/types/group-stage-bracket'}>Group Stage</Link>
				<Link href={'/types/prediction-bracket'}>Predictions</Link>
				<Link href={'/types/images-bracket'}>Brackets with Images</Link>
				<Link href={'/types/voting-bracket'}>Voting Brackets</Link>
			</div>
		</PopoverDiv>
	);
};
