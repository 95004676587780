import React, { AnchorHTMLAttributes, HTMLAttributeAnchorTarget, ReactNode } from 'react';
import { PrimaryAnchor, PrimaryLink, SecondaryAnchor, SecondaryLink } from './link.styles';

interface ILinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  variant?: 'primary' | 'secondary';
  external?: boolean;
}

export const CustomLink = ({ children, variant, external = false, ...props }: ILinkProps) => {
  if (props.href && props.href.startsWith('//')) {
    // Remove first slash
    props.href = props.href.substring(1);
  }
  if (variant === 'secondary') {
    if (external) {
      return <SecondaryAnchor {...props}>{children}</SecondaryAnchor>
    }
    return (
      <SecondaryLink {...props as any}>
        {children}
      </SecondaryLink>
    );
  }
  if (external) {
    return <PrimaryAnchor {...props}>{children}</PrimaryAnchor>;
  }
  return (
    <PrimaryLink {...props as any}>
      {children}
    </PrimaryLink>
  );
};
