import Link from "next/link";
import React from "react";
import { StyledFooter } from "./footer.styles";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { GrLinkedinOption } from "@react-icons/all-files/gr/GrLinkedinOption";
// import { SiDiscord } from '@react-icons/all-files/si/SiDiscord';
import { IoIosArrowForward } from "@react-icons/all-files/io/IoIosArrowForward";
import { IBlogPage, IPlatform, IPlugin } from "../../types";

// @ts-ignore
import platforms from "platforms.json";
// @ts-ignore
import posts from "posts.json";

export const Footer = ({ isDark = false }: { isDark: boolean }) => {
  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter className={isDark ? "dark" : ""}>
      <div className="content-wrapper">
        <div className="top">
          <div className="column">
            <h2>Company</h2>
            <Link href={"/about-us"}>About Us</Link>
            <a
              href={
                "https://www.commoninja.com/embed/website/pricing/bracketsninja?mode=dark&via=brackets"
              }
            >
              Pricing
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={"https://www.commoninja.com/platform/"}
            >
              Developers
            </a>
            <Link
              rel="noopener noreferrer"
              href={"https://help.commoninja.com/hc/en-us/requests/new"}
              target="_blank"
            >
              Contact Us
            </Link>
            <Link
              rel="noopener noreferrer"
              className="blue"
              href={"https://www.commoninja.com/widgets?via=brackets"}
              target="_blank"
            >
              See All Widgets
              <IoIosArrowForward className="icon" />
            </Link>
            {/* <Link
							href={'https://discord.com/invite/cxqUTbvMNd'}
							target="_blank"
						>
							<SiDiscord className="icon" />
							Discord
						</Link> */}
            {/* <Link href={'/'}>Homepage</Link> */}
            {/* <Link href={'/widgets'}>Our Widgets</Link> */}
            {/* <Link href={'/platforms'}>Supported Platforms</Link> */}
          </div>
          <div className="column">
            <h2>Bracket Types</h2>
            <Link href={"/how-to-create-online-NCAA-2024-bracket"}>
              March Madness 2024
            </Link>
            <Link href={"/types/single-elimination-bracket"}>
              Single Elimination
            </Link>
            <Link href={"/types/double-elimination-bracket"}>
              Double Elimination
            </Link>
            <Link href={"/types/march-madness-bracket"}>March Madness</Link>
            <Link href={"/types/round-robin-bracket"}>Round Robin</Link>
            <Link href={"/types/group-stage-bracket"}>Group Stage</Link>
            <Link href={"/types/prediction-bracket"}>Predictions</Link>
            <Link href={"/types/images-bracket"}>Brackets with Images</Link>
            <Link href={"/types/voting-bracket"}>Voting Brackets</Link>
            <Link className="blue" href={"/examples"}>
              Examples
              <IoIosArrowForward className="icon" />
            </Link>
          </div>
          <div className="column">
            <h2>Platforms</h2>
            {platforms.slice(0, 10).map((platform: IPlatform) => (
              <Link key={platform.slug} href={`/platforms/${platform.slug}`}>
                {platform.title}
              </Link>
            ))}
            <Link className="blue" href={"/platforms"}>
              See All Platforms
              <IoIosArrowForward className="icon" />
            </Link>
          </div>
          <div className="column">
            <h2>Follow Us</h2>
            <Link
              rel="noopener noreferrer"
              href={"https://www.facebook.com/CommonNinja/"}
              target="_blank"
            >
              <FaFacebookF className="icon" />
              Facebook
            </Link>
            <Link
              rel="noopener noreferrer"
              href={"https://www.linkedin.com/company/common-ninja"}
              target="_blank"
            >
              <GrLinkedinOption className="icon" />
              LinkedIn
            </Link>
            <Link
              rel="noopener noreferrer"
              href={"https://www.youtube.com/@commonninja"}
              target="_blank"
            >
              <FaYoutube className="icon" />
              YouTube
            </Link>
            <Link
              rel="noopener noreferrer"
              href={"https://www.instagram.com/commonninja_official/"}
              target="_blank"
            >
              <FaInstagram className="icon" />
              Instagram
            </Link>
            <Link
              rel="noopener noreferrer"
              href={"https://www.tiktok.com/@commonninja_official"}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2859 3333"
                fill="none"
                style={{ fill: "currentColor", width: 15, height: 15 }}
                className="icon"
              >
                <path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z" />
              </svg>
              TikTok
            </Link>
            <Link
              rel="noopener noreferrer"
              href={"https://twitter.com/commonninja"}
              target="_blank"
            >
              <FaTwitter className="icon" />
              Twitter
            </Link>
          </div>
          <div className="column blog-posts">
            <h2>Blog</h2>
            {posts.map((post: IBlogPage) => (
              <Link key={post.slug} href={`/blog/${post.slug}`}>
                {post.name}
              </Link>
            ))}
            <Link className="blue" href={"/blog"}>
              See All Blog Posts
              <IoIosArrowForward className="icon" />
            </Link>
          </div>
        </div>
        <div className="bottom">
          <small>
            © Copyright 2012-{currentYear}, Common Ninja. All rights reserved.
          </small>
          <div className="bottom-links">
            <Link href={"/terms"}>Terms & Conditions</Link>
            <Link href={"/privacy"}>Privacy Policy</Link>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};
