import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { StyledMiniHeader, StyledHeader, StyledAside } from './header.styles';
import { INewTag, IPlugin } from '../../types';
import {
	TypesPopover,
} from './menuPopovers/menuPopovers.comp';
import { GiHamburgerMenu } from '@react-icons/all-files/gi/GiHamburgerMenu';
import { useRouter } from 'next/router';
import { ReactDimmer } from 'react-dimmer';
import { CSSProperties } from 'styled-components';
import { trackEvent } from '../../lib/track';
import { CustomLink } from '../link/link.comp';


interface IHeaderProps {
	style?: CSSProperties;
	isDark?: boolean;
	isDeveloper?: boolean;
	className?: string;
	logoPath?: '/' | '';
	is404?: boolean;
}

export const Header = ({
	style = {},
	isDark = false,
	isDeveloper = false,
	className = '',
	logoPath = '',
	is404 = false
}: IHeaderProps) => {
	const [isBrackets, setIsBrackets] = useState<boolean>(false);
	const [showTypesPopover, setShowTypesPopover] = useState<boolean>(false);
	const [sideNavOpen, setSideNavOpen] = useState<boolean>(false);
	const router = useRouter();

	// content={<AppsPopover columns={columns} isDark={isDark} show={showAppsPopover} setShow={setShowAppsPopover} />}
	function renderLinks() {
		return (
			<div className="links">
				<div>
					<Link href={'/examples'}>
						Examples
					</Link>
				</div>
				<div
					className="link-wrapper"
					onMouseEnter={() => setShowTypesPopover(true)}
					onMouseLeave={() => setShowTypesPopover(false)}
				>
					<div>
						<Link href="/types">Bracket Types</Link>
						<TypesPopover show={showTypesPopover} isDark={isDark} />
					</div>
				</div>
				<div>
					<a href={'https://www.commoninja.com/embed/website/pricing/bracketsninja?mode=dark&via=brackets'}>
						Pricing
					</a>
				</div>
			</div>
		);
	}

	function renderSideNav() {
		return (
			<>
				<StyledMiniHeader
					style={style}
					className={`${isDark ? 'dark' : ''} ${className}`}
				>
					<nav>
						<Link
							href={'/'}
							className="logo"
						>
							{(isDark || is404) ? (
								<Image
									src={'/assets/cn-logo-dark.svg'}
									alt="Common ninja logo"
									width={200}
									height={30}
								/>
							) : (
								<Image
									src={'/assets/cn-logo-light.svg'}
									alt="Common ninja logo"
									width={200}
									height={30}
								/>
							)}
						</Link>
						<GiHamburgerMenu
							onClick={() => setSideNavOpen(true)}
							fontSize={23}
							className={`hamburger ${isDark ? 'dark' : ''}`}
						/>
					</nav>
				</StyledMiniHeader>
				<StyledAside
					className={`${isDark ? 'dark' : ''} ${sideNavOpen ? 'opened' : ''}`}
				>
					<div className="top">
						<Link
							onClick={() => setSideNavOpen(false)}
							href={isDark && !logoPath ? '/developers' : '/'}
						>
							{(isDark || is404) ? (
								<Image
									src={'/assets/cn-logo-dark.svg'}
									alt="Common ninja logo"
									width={200}
									height={30}
								/>
							) : (
								<Image
									src={'/assets/cn-logo-light.svg'}
									alt="Common ninja logo"
									width={200}
									height={30}
								/>
							)}
						</Link>
					</div>
					<div className="content">
						<nav>
							<Link
								className={`${router.pathname === '/examples' ? 'active' : ''}`}
								onClick={() => setSideNavOpen(false)}
								href="/examples"
							>
								Examples
							</Link>
							<a
								className={`${router.pathname === '/pricing' ? 'active' : ''}`}
								onClick={() => setSideNavOpen(false)}
								href={'https://www.commoninja.com/embed/website/pricing/bracketsninja?mode=dark&via=brackets'}
							>
								Pricing
							</a>
							<div className="sub-items">
								<p className="title">Bracket Types</p>
								<div className="items">
									<Link
										className={`${router.pathname === '/types/single-elimination-bracket' ? 'active' : ''}`}
										onClick={() => setSideNavOpen(false)}
										href={'/types/single-elimination-bracket'}>Single Elimination</Link>
									<Link
										className={`${router.pathname === '/types/double-elimination-bracket' ? 'active' : ''}`}
										onClick={() => setSideNavOpen(false)}
										href={'/types/double-elimination-bracket'}>Double Elimination</Link>
									<Link
										className={`${router.pathname === '/types/march-madness-bracket' ? 'active' : ''}`}
										onClick={() => setSideNavOpen(false)}
										href={'/types/march-madness-bracket'}>March Madness</Link>
									<Link
										className={`${router.pathname === '/types/round-robin-bracket' ? 'active' : ''}`}
										onClick={() => setSideNavOpen(false)}
										href={'/types/round-robin-bracket'}>Round Robin</Link>
									<Link
										className={`${router.pathname === '/types/group-stage-bracket' ? 'active' : ''}`}
										onClick={() => setSideNavOpen(false)}
										href={'/types/group-stage-bracket'}>Group Stage</Link>
									<Link
										className={`${router.pathname === '/types/prediction-bracket' ? 'active' : ''}`}
										onClick={() => setSideNavOpen(false)}
										href={'/types/prediction-bracket'}>Brackets with Predictions</Link>
									<Link
										className={`${router.pathname === '/types/images-bracket' ? 'active' : ''}`}
										onClick={() => setSideNavOpen(false)}
										href={'/types/images-bracket'}>Brackets with Images</Link>
									<Link
										className={`${router.pathname === '/types/voting-bracket' ? 'active' : ''}`}
										onClick={() => setSideNavOpen(false)}
										href={'/types/voting-bracket'}
									>
										Voting Brackets
									</Link>
								</div>
							</div>
							<div className="sub-items">
								<p className="title">Support</p>
								<div className="items">
									<Link
										rel="noopener noreferrer"
										className={`${router.pathname === '/' ? '' : ''}`}
										href="https://help.commoninja.com/hc/en-us"
										target={'_blank'}
									>
										Help Center
									</Link>
									<Link
										rel="noopener noreferrer"
										className={`${router.pathname === '/' ? '' : ''}`}
										href="https://help.commoninja.com/hc/en-us/requests/new"
										target={'_blank'}
									>
										Contact Us
									</Link>
								</div>
							</div>
						</nav>
					</div>
					<div className="bottom">
						<Link
							href="http://commonninja.com/login"
							onClick={() => {
								trackEvent('Website Login');
							}}
						>
							Log In
						</Link>
					</div>
				</StyledAside>
			</>
		);
	}

	useEffect(() => {
		if (
			typeof window !== 'undefined' &&
			window.location.pathname.includes('brackets')
		) {
			setIsBrackets(true);
		} else {
			setIsBrackets(false);
		}
	}, []);

	return (
		<>
			<ReactDimmer isOpen={sideNavOpen} exitDimmer={setSideNavOpen} blur={1} />
			{renderSideNav()}
			<StyledHeader style={style} className={`${isDark ? 'dark' : ''}`}>
				<nav>
					<div className="links-divider">
						<Link href={'/'} className="logo">
							{(isDark || is404) ? (
								<Image
									src={'/assets/cn-logo-dark.svg'}
									alt="Common ninja logo"
									width={200}
									height={30}
									priority
								/>
							) : (
								<Image
									src={'/assets/cn-logo-light.svg'}
									alt="Common ninja logo"
									width={200}
									height={30}
									priority
								/>
							)}
						</Link>
						{renderLinks()}
					</div>
					<div className="links-divider">
						<CustomLink
							style={{ marginRight: '30px', color: 'white' }}
							className="cta"
							href="https://www.commoninja.com/brackets/editor?via=brackets"
						>
							Try for free
						</CustomLink>
						<Link
							className="login"
							href="http://commonninja.com/login"
							onClick={() => {
								trackEvent('Website Login');
							}}
						>
							Log In
						</Link>
					</div>
				</nav>
			</StyledHeader>
		</>
	);
};
